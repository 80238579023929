import { UsersEntity } from '../users/users.slice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const PRESENCE_FEATURE_KEY = 'presence';

export interface PresenceState {
    activeUsers: Record<string, {
        id: string;
        name: string;
        status: 'viewing' | 'editing';
        lastSeen: string;
        avatar: string;
    }>;
    presenceByReport: Record<string, Record<string, {
        id: string;
        name: string;
        status: 'viewing' | 'editing';
        lastSeen: string;
        avatar: string;
    }>>;
}

const initialState: PresenceState = {
    activeUsers: {},
    presenceByReport: {}
};

export const presenceSlice = createSlice({
    name: PRESENCE_FEATURE_KEY,
    initialState,
    reducers: {
        userJoined: (state, action: PayloadAction<{
            id: string;
            name: string;
            status: 'viewing' | 'editing';
            type?: 'app' | 'report';
            avatar: string;
            typeId?: string;
        }>) => {
            state.activeUsers[action.payload.id] = {
                ...action.payload,
                lastSeen: new Date().toISOString()
            };

            if (action.payload.type === 'report') {
                if (!state.presenceByReport[action.payload.typeId]) {
                    state.presenceByReport[action.payload.typeId] = {};
                }
                state.presenceByReport[action.payload.typeId][action.payload.id] = {
                    ...action.payload,
                    lastSeen: new Date().toISOString()
                };
            }
        },
        userUpdated: (state, action: PayloadAction<{
            id: string;
            status: 'viewing' | 'editing';
            avatar?: string;
            type?: 'app' | 'report';
            typeId?: string;
        }>) => {
            const user = state.activeUsers[action.payload.id];
            if (user) {
                user.status = action.payload.status;
                user.lastSeen = new Date().toISOString();
                if (action.payload.avatar) {
                    user.avatar = action.payload.avatar;
                }
            }

            if (action.payload.type === 'report') {
                const user = state.presenceByReport[action.payload.typeId]?.[action.payload.id];
                if (user) {
                    user.status = action.payload.status;
                    user.lastSeen = new Date().toISOString();
                    if (action.payload.avatar) {
                        user.avatar = action.payload.avatar;
                    }
                }
            }
        },
        userLeft: (state, action: PayloadAction<string>) => {
            delete state.activeUsers[action.payload];
        }
    }
});

export const { userJoined, userUpdated, userLeft } = presenceSlice.actions;
export default presenceSlice.reducer;
