import { rAPI } from './rAPI';
import { ReportTestItem } from './types';

// Utility function for consistent cache updates
const updateItemInReportCache = (
  dispatch,
  getState,
  reportId: string,
  testItemId: number,
  updateFn: (item: ReportTestItem) => ReportTestItem
) => {
  // Update single item cache if it exists
  const singleItemCacheKey = { reportId, testItemId };
  const singleItemData = rAPI.endpoints.getSingleReportItem.select(
    singleItemCacheKey
  )(getState())?.data;

  let singleItemPatch;
  if (singleItemData) {

    singleItemPatch = dispatch(
      rAPI.util.updateQueryData(
        'getSingleReportItem',
        singleItemCacheKey,
        (draft) => Object.assign(draft, updateFn(singleItemData))
      )
    );
  }

  // Update list cache if it exists
  const listData = rAPI.endpoints.getReportItems.select(reportId)(
    getState()
  )?.data;

  let listPatch;
  if (listData) {

    listPatch = dispatch(
      rAPI.util.updateQueryData('getReportItems', reportId, (draft) => {
        const itemIndex = draft.findIndex(
          (item) => item.assessmentTestId === testItemId
        );
        if (itemIndex !== -1) {
          // draft[itemIndex] = updateFn({...draft[itemIndex]});
          const updatedItem = updateFn(draft[itemIndex] as ReportTestItem);
          Object.assign(draft[itemIndex], updatedItem);
        }
      })
    );
  }

  return {
    undo: () => {
      if (singleItemPatch) singleItemPatch.undo();
      if (listPatch) listPatch.undo();
    },
  };
};

export default updateItemInReportCache;
