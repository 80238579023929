import { history } from '@app.raytd.com/common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../root';
import { UsersEntity } from '../users/users.slice';
import apiURL from '../api/url';
import { api } from '../api/fetch';
import { CompanyEntity } from '../company/company.slice';


const saveUserToLocalStorage = (user: AuthenticatedResponse) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const removeUserFromLocalStorage = () => {
  localStorage.removeItem('user');
};

export const AUTH_FEATURE_KEY = 'auth';

export type AuthState = {
  isLoggedIn: boolean;
  xsrfToken: string | null;
  user: AuthenticatedResponse | null;
  //loggedInUser: UsersEntity | null;
  error: string | null;
  onboardingStatus: 'acknowledged' | 'complete' | 'idle' | 'pending';
  userLoading: boolean;
  isImpersonating: boolean;
};

export const getStoredAuth = (): AuthenticatedResponse | null => {
  if (typeof localStorage === 'undefined') {
    console.info('No localstore defined');
    return null;
  }

  try {

    const user = JSON.parse(localStorage.getItem('user') || '{}');

    console.info('Retrieved user from localStorage:', user);

    if (user?.token) {
      return user as AuthenticatedResponse;
    }
  } catch (e) {
    console.error('error parsing user', e);
  }

  return null;
};

const storedUser = getStoredAuth();

export const initialAuthState: AuthState = {
  isLoggedIn: !!storedUser,
  user: storedUser ?? { token: '', email: '', user: {} as UsersEntity },
  error: null,
  xsrfToken: null,
  onboardingStatus: 'idle',
  userLoading: false,
  isImpersonating: false,
};

type AuthenticatedResponse = {
  token: string;
  email: string;
  user?: UsersEntity;
};

export type LoginCredentials = {
  email: string;
  password: string;
};

const authenticate = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/login`,
  async (
    credentials: LoginCredentials,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const response = await api.post('/api/v1/login', credentials);
      console.debug('authenticate response', response.body);
      return response.body as AuthenticatedResponse;
    } catch (err) {
      console.debug('rejected authenticate', err);
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

interface LoginError {
  message: string
  code?: string
  details?: Record<string, string[]>
}

const login = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/initializeAppAfterLogin`,
  async (
    credentials: LoginCredentials,
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = (getState() as RootState).auth

    try {
      const loginAction = await dispatch(authenticate(credentials));
      
      // Handle authentication errors
      if (!authenticate.fulfilled.match(loginAction)) {
        const error: LoginError = {
          message: 'Authentication failed',
          code: 'AUTH_FAILED',
          details: {
            credentials: ['Invalid email or password']
          }
        }
        return rejectWithValue(error)
      }

      const token = loginAction.payload
      console.debug('token', token)
      
      // Get user profile
      const userAction = await dispatch(getLoggedInUserProfile())

      if (getLoggedInUserProfile.fulfilled.match(userAction)) {
        const user = userAction.payload.user
        console.debug('User login successful', user)
        return user
      }

      // Handle user profile fetch errors
      const profileError: LoginError = {
        message: 'Failed to load user profile',
        code: 'PROFILE_FETCH_FAILED',
        details: {
          profile: [userAction.error?.message ?? 'Unable to fetch user data']
        }
      }
      return rejectWithValue(profileError)

    } catch (err) {
      // Handle network or unexpected errors
      const error: LoginError = {
        message: err instanceof Error ? err.message : 'Login failed',
        code: 'UNEXPECTED_ERROR',
        details: {
          system: ['An unexpected error occurred during login']
        }
      }

      console.debug('login error err ', err)

      // Handle specific API errors
      if (err instanceof Error && 'status' in err) {
        switch ((err as any).status) {
          case 401:
            error.message = 'Invalid credentials'
            error.code = 'INVALID_CREDENTIALS'
            break
          case 403:
            error.message = 'Account locked'
            error.code = 'ACCOUNT_LOCKED'
            break
          case 429:
            error.message = 'Too many login attempts'
            error.code = 'RATE_LIMITED'
            break
          case 500:
            error.message = 'Server error'
            error.code = 'SERVER_ERROR'
            break
        }
      }

      return rejectWithValue(error)
    }
  }
)

const loginWithToken = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/loginWithToken`,
  async (token: string, { dispatch, rejectWithValue }) => {
    try {
      // First set the token in the state
      dispatch(authSlice.actions.setToken(token))
      
      // Then fetch the user profile
      const userAction = await dispatch(getLoggedInUserProfile())
      
      if (getLoggedInUserProfile.fulfilled.match(userAction)) {
        const user = userAction.payload.user
        console.debug('Token login successful', user)
        
        // Set login state
        dispatch(authSlice.actions.validateLogin())
        return user
      }

      return rejectWithValue(userAction.error?.message ?? 'Failed to get user profile')
    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Token login failed'
      )
    }
  }
)

export const logout = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/logout`,
  async (_, { dispatch }) => {
    try {
      //await api.logout();
      removeUserFromLocalStorage();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }
);

const getLoggedInUserProfile = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/user`,
  async (_, { getState, rejectWithValue }) => {
    const state = (getState() as RootState).auth;

    console.debug('User data dispatched');

    try {
      const response = await api.get('/api/v1/user');

      return {
        user: response.body.data as UsersEntity,
      } as AuthenticatedResponse;

    } catch (err) {
      return rejectWithValue(
        err instanceof Error ? err.message : 'Unknown error'
      );
    }
  }
);

const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');

  const cookie = cookies.find((cookie) => cookie.trim().startsWith(`${name}=`));
  if (!cookie) {
    return null;
  }

  return cookie.split('=')[1];
};

const csrf = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/csrf`,
  async (_, { rejectWithValue }) => {
    const response = await fetch(apiURL('/sanctum/csrf-cookie'), {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    });

    if (!response.ok) {
      return rejectWithValue(response.statusText);
    }

    const cookie = getCookie('XSRF-TOKEN');
    if (!cookie) {
      return rejectWithValue('No cookie');
    }

    const token = decodeURIComponent(cookie);

    return token;
  }
);


export const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState: initialAuthState,
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;

      removeUserFromLocalStorage();

      state.user = null;
      state.error = null;
      state.xsrfToken = null;
      state.onboardingStatus = 'idle';
      history.navigate('/login');
    },
    validateLogin: (state) => {
      state.isLoggedIn = true;
    },
    updateOnboardingStatus: (state, action) => {
      state.onboardingStatus = action.payload;
    },
    loginUser: (state, action: PayloadAction<{user:UsersEntity; token:string; }>) => {
      state.isLoggedIn = true;
      state.user = {
        token: action.payload.token,
        email: action.payload.user.email
      }
    },
    setImpersonating: (state, action: PayloadAction<boolean>) => {
      state.isImpersonating = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      console.log('Setting token:', action.payload);
      state.user.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state: AuthState) => {
        state.error = null;
      })
      .addCase(authenticate.fulfilled, (state: AuthState, action) => {
        const user = action.payload;
        state.isLoggedIn = true;
        state.user = {
          token: user.token,
          email: user.email,
        };
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoggedIn = false;
        state.user = null;
        state.error = null;
        state.xsrfToken = null;
        state.onboardingStatus = 'idle';
      })
      .addCase(authenticate.rejected, (state: AuthState, action) => {
        state.isLoggedIn = false;
        state.error = action.error?.message || 'Error';
      })
      .addCase(csrf.fulfilled, (state, action) => {
        state.xsrfToken = action.payload;
        console.info('csrf fulfilled', action.payload, state.xsrfToken);
      })
      .addCase(csrf.rejected, (state) => {
        state.error = 'token rejected';
      })
      .addCase(login.pending, (state) => {
        state.userLoading = true;
      })
      .addCase(getLoggedInUserProfile.fulfilled, (state, action) => {
        const user = action.payload.user;
        state.error = null;

        state.user = {
          ...state.user,
          user: user,
        } as AuthenticatedResponse;

        if (state.user.user?.company === null) {
          if (state.onboardingStatus === 'idle') {
            state.onboardingStatus = 'pending';
          }
        } else {
          state.onboardingStatus = 'complete';
        } 
          
        console.info('getUser.fulfilled', state.user, state.onboardingStatus);

        saveUserToLocalStorage(state.user);

        state.userLoading = false;
      })
      .addCase(getLoggedInUserProfile.rejected, (state, action) => {
        state.error = action.error?.message || 'Error';
        state.userLoading = false;
      });
  },
});

export const getAuthState = (rootState: {
  [AUTH_FEATURE_KEY]: AuthState;
}): AuthState => rootState[AUTH_FEATURE_KEY];

export const selectAuth = (state: RootState) => state.auth;
export const selectXsrfToken = (state: RootState) => state.auth.xsrfToken;

export const selectLoggedInUser = (state: RootState): UsersEntity | null =>
  state.auth.user?.user ?? null;
export const selectIsLoggedIn = (state: RootState): boolean =>
  state.auth.isLoggedIn;

export const selectUserIsLoading = (state: RootState): boolean =>
  state.auth.userLoading;

export const selectUserOrganisation = (
  state: RootState
): CompanyEntity | null => state.auth.user?.user?.company ?? null;

export const selectOnboardingStatus = (state: RootState) =>
  state.auth.onboardingStatus;

export const authReducer = authSlice.reducer;
export const authActions = { ...authSlice.actions, 
  login, getUser: getLoggedInUserProfile, csrf, loginWithToken

};
