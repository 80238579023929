import { combineReducers } from '@reduxjs/toolkit';

import { ALERTS_FEATURE_KEY, alertsSlice } from '../alerts/alerts.slice';
import { UISTATE_FEATURE_KEY, uiSlice } from '../application/ui-state.slice';
import { ASSET_FEATURE_KEY, assetSlice } from '../asset/asset.slice';
import { BUILDINGS_FEATURE_KEY, buildingsSlice } from '../asset/buildings.slice';
import { LEVELS_FEATURE_KEY, levelSlice } from '../asset/levels.slice';
import { ROOMS_FEATURE_KEY, roomsSlice } from '../asset/rooms.slice';
import { AUTH_FEATURE_KEY, authSlice } from '../auth/auth.slice';
import { ORGANISATION_FEATURE_KEY, organisationSlice } from '../organisation/organisation.slice';
import { SITEMAP_FEATURE_KEY, sitemapSlice } from '../sitemap/sitemap.slice';
import { RATINGSCALES_FEATURE_KEY, ratingScaleSlice } from '../tests/rating-scales.slice';
import { TEST_SPECIFICATIONS_FEATURE_KEY, testSpecificationsSlice } from '../tests/test-specifications.slice';
import { TESTSUITES_FEATURE_KEY, testsuitesSlice } from '../tests/testsuites.slice';
import { USERS_FEATURE_KEY, usersSlice } from '../users/users.slice';
import { CLASSIFICATION_TREES_FEATURE_KEY, classificationTreeSlice } from '../element/classification-tree.slice';
import { ELEMENT_CLASSIFICATION_FEATURE_KEY, elementClassificationsSlice } from '../element/element-classification.slice';
import { rAPI } from '../services';
import {uploadSlice, IMAGE_UPLOAD_SLICE_KEY} from '../images/image-upload-slice';
import { SHARED_REPORTS_FEATURE_KEY, sharedReportsSlice } from '../api/shared-reports.slice';
import { PRESENCE_FEATURE_KEY, presenceSlice } from '../presence/presence.slice';

export const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authSlice.reducer,
  [ALERTS_FEATURE_KEY]: alertsSlice.reducer,
  [SITEMAP_FEATURE_KEY]: sitemapSlice.reducer,
  [USERS_FEATURE_KEY]: usersSlice.reducer,
  [ASSET_FEATURE_KEY]: assetSlice.reducer,
  [ORGANISATION_FEATURE_KEY]: organisationSlice.reducer,
  [TESTSUITES_FEATURE_KEY]: testsuitesSlice.reducer,
  [TEST_SPECIFICATIONS_FEATURE_KEY]: testSpecificationsSlice.reducer,
  [UISTATE_FEATURE_KEY]: uiSlice.reducer,
  [BUILDINGS_FEATURE_KEY]: buildingsSlice.reducer,
  [LEVELS_FEATURE_KEY]: levelSlice.reducer,
  [ROOMS_FEATURE_KEY]: roomsSlice.reducer,
  [RATINGSCALES_FEATURE_KEY]: ratingScaleSlice.reducer,
  [CLASSIFICATION_TREES_FEATURE_KEY]: classificationTreeSlice.reducer,
  [ELEMENT_CLASSIFICATION_FEATURE_KEY]: elementClassificationsSlice.reducer,
  [rAPI.reducerPath]: rAPI.reducer,
  [IMAGE_UPLOAD_SLICE_KEY]: uploadSlice.reducer,
  [SHARED_REPORTS_FEATURE_KEY]: sharedReportsSlice.reducer,
  [PRESENCE_FEATURE_KEY]: presenceSlice.reducer,
});

export default rootReducer;