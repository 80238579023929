import { createSlice } from '@reduxjs/toolkit';

export const SHARED_REPORTS_FEATURE_KEY = 'sharedReports';

export const sharedReportsSlice = createSlice({
    name: SHARED_REPORTS_FEATURE_KEY,
    initialState: {
      accessToken: null,
      reportId: null,
      revisionId: null,
    },
    reducers: {
      setSharedReportAccess: (state, action) => {
        state.accessToken = action.payload.token;
        state.reportId = action.payload.report_id;
        state.revisionId = action.payload.revision_id;
      },
      clearSharedReportAccess: (state) => {
        state.accessToken = null;
        state.reportId = null;
        state.revisionId = null;
      }
    }
});

export const { setSharedReportAccess, clearSharedReportAccess } = sharedReportsSlice.actions;
export default sharedReportsSlice.reducer;
