import {
  createEntityAdapter,
  createSelector,
  EntityState,
} from '@reduxjs/toolkit';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { toast } from 'sonner';
import { Assessment } from '../assessment/assessment.slice';
import { AssetEntity } from '../asset';
import { authActions } from '../auth';
import { RootState } from '../root';
import { TestItem, TestSuite } from '../tests/entity';
import { TestSpecificationsEntity } from '../tests/test-specifications.slice';
import {
  ClassificationItem,
  ReportBlock,
  ReportEntity,
  ReportResponse,
  ReportPayload,
  ReportRevision,
  ReportUpdatePayload,
  AddCustomFieldOptionResponse,
  AddCustomFieldOptionRequest,
  PublishReportResponse,
} from './types';
import { UsersEntity } from '../users';
import {
  ReportSection,
  ToggleSwitchResponse,
  ToggleSwitchRequest,
  ReportTestItem,
  PublishReportPayload,
  PublishedReport,
  PublishedReportResponse
} from './types';
import { transformClassificationTree } from './elementTransformer';
import updateItemInReportCache from './updateItemInReportCache';
import { string } from 'slate';

const API_URL = (import.meta.env.VITE_API_URL as string) + '/api/v1/';
const mutex = new Mutex();

console.debug('API_URL:', API_URL);

interface PaginatedResponse<T> {
  data: T[];
  meta: {
    current_page: number;
    last_page: number;
    per_page: number;
    total: number;
  };
}

const baseHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState() as RootState;
    const token = state.auth.user?.token || state.sharedReports.accessToken;
    const csrf = state.auth.xsrfToken;

    // Set default headers
    Object.entries(baseHeaders).forEach(([key, value]) => {
      headers.set(key, value);
    });

    // Handle FormData content type
    const isFormData = headers
      .get('Content-Type')
      ?.includes('multipart/form-data');
    if (isFormData) {
      headers.delete('Content-Type'); // Let browser set it
    }

    // Set auth headers
    if (csrf) headers.set('X-XSRF-TOKEN', csrf);
    if (token) headers.set('Authorization', `Bearer ${token}`);

    console.debug('RTK Query base headers:', headers);

    return headers;
  },
  credentials: 'include',
});

const customBaseQuery: BaseQueryFn = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);

  // If we get a 401 error, attempt to refresh the token
  if (result.error?.status === 401) {
    // Lock the mutex so other queries wait until the token refresh is complete
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        //   const refreshResult = await baseQuery('/auth/refresh-token', api, extraOptions);

        //   if (refreshResult.data) {
        //     // Dispatch the new token to the store
        //     api.dispatch(refreshToken(refreshResult.data));

        //     // Retry the original query with the new token
        //     result = await baseQuery(args, api, extraOptions);
        //   } else {
        //     // If the refresh fails, log out the user
        //     api.dispatch(logout());
        //   }
        api.dispatch(authActions.logout());
      } finally {
        // Release the mutex no matter what happens
        release();
      }
    } else {
      // If another request is refreshing the token, wait for it to complete
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

const assetsAdapter = createEntityAdapter<AssetEntity>();
const initialState = assetsAdapter.getInitialState();

function getAllItemIds(item: ClassificationItem): number[] {
  return [item.id, ...(item.children?.flatMap(getAllItemIds) ?? [])];
}

const reportSectionTags = (reportId?: number, sectionId?: string) =>
  [
    { type: 'ReportSections' as const, id: reportId },
    sectionId && { type: 'ReportSection' as const, id: sectionId },
  ].filter(Boolean);

export const rAPI = createApi({
  reducerPath: 'assetApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'Asset',
    'Assets',
    'CoverImage',
    'Assessments',
    'Assessment',
    'Buildings',
    'Levels',
    'Areas',
    'Building',
    'Level',
    'Area',
    'User',
    'Users',
    'Qualifications',
    'Offices',
    'Organisations',
    'ElementGroups',
    'ElementTree',
    'ElementItems',
    'ElementItem',
    'Recommendations',
    'Observations',
    'TestSuites',
    'Specifications',
    'RatingScales',
    'TestItems',
    'TestItem',
    'ReportBlocks',

    'Report',
    'Reports',
    'ReportRevision',
    'ReportSections',
    'ReportSection',
  ],
  endpoints: (builder) => ({
    extendAndResendInvite: builder.mutation({
      query: (invitationId) => ({
        url: `organisations/invite/${invitationId}/extend`,
        method: 'POST',
      }),
    }),

    getAssets: builder.query<EntityState<AssetEntity, number>, void>({
      query: () => 'assets',
      transformResponse: (response: { data: AssetEntity[] }) => {
        //return response.data;
        return assetsAdapter.setAll(initialState, response.data);
      },
      providesTags: (result) =>
        result?.ids
          ? [
              ...result.ids.map((id) => ({ type: 'Asset' as const, id })),
              { type: 'Assets' as const, id: 'LIST' },
            ]
          : [{ type: 'Assets', id: 'LIST' }],
    }),
    getAsset: builder.query({
      query: (assetId) => `asset/${assetId}`,
      // providesTags: (result, error, id) => [{ type: 'Asset', id }],
      providesTags: (result, error, id) =>
        result
          ? [
              { type: 'Asset', id },
              ...(result.buildings?.map((building) => ({
                type: 'Building' as const,
                id: building.id,
              })) ?? []),
              ...(result.buildings?.flatMap((building) =>
                building.levels?.map((level) => ({
                  type: 'Level' as const,
                  id: level.id,
                }))
              ) ?? []),
              ...(result.buildings?.flatMap((building) =>
                building.levels?.flatMap((level) =>
                  level.rooms?.map((location) => ({
                    type: 'Area' as const,
                    id: location.id,
                  }))
                )
              ) ?? []),
            ]
          : [{ type: 'Asset', id }],
    }),
    createAsset: builder.mutation({
      query: ({ organisationId, ...asset }) => ({
        url: `organisations/${organisationId}/assets`,
        method: 'POST',
        body: asset,
      }),
      invalidatesTags: [{ type: 'Assets', id: 'LIST' }],
    }),
    updateAsset: builder.mutation({
      query: ({ id, ...asset }) => ({
        url: `/asset/${id}`,
        method: 'PUT',
        body: asset,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Asset', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    retireAsset: builder.mutation({
      query: (assetId) => ({
        url: `/asset/${assetId}/retire`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Asset', id },
        { type: 'Assets', id: 'LIST' },
      ],
    }),
    changeUserRole: builder.mutation({
      query: ({ assetId, user_id, role }) => ({
        url: `/asset/${assetId}/role`,
        method: 'PUT',
        body: {
          user_id,
          role,
        },
      }),
      invalidatesTags: ['Asset'],
    }),
    archiveAsset: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/archive`,
        method: 'PATCH',
      }),
      async onQueryStarted(assetId, lifecycleApi) {
        const getAssetsPatchResult = lifecycleApi.dispatch(
          rAPI.util.updateQueryData('getAssets', undefined, (draft) => {
            const asset = draft.entities[assetId];

            if (asset) {
              if (asset.status === 'archived') {
                asset.status = 'active';
                asset.is_globally_archived = false;
              } else {
                asset.status = 'archived';
                asset.is_globally_archived = true;
              }
            }
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
          //          const state = lifecycleApi.getState();
          //        const updatedAsset = state.assetApi.queries['getAssets']?.data?.entities[assetId];

          //      toast.success(`Asset ${updatedAsset.status === 'active' ? 'restored' : 'archived'} successfully`);
          toast.success(`Asset updated`);
        } catch (error) {
          console.error('Error archiving asset', error);
          toast.error('Error archiving asset');
          getAssetsPatchResult.undo();
        }
      },
      // invalidatesTags: ['Asset'],
    }),

    archiveAssetForUser: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/status`,
        method: 'PATCH',
      }),
      async onQueryStarted(assetId, lifecycleApi) {
        const getAssetsPatchResult = lifecycleApi.dispatch(
          rAPI.util.updateQueryData('getAssets', undefined, (draft) => {
            const asset = draft.entities[assetId];

            if (asset) {
              if (asset.status === 'archived') {
                asset.status = 'active';
              } else {
                asset.status = 'archived';
              }
            }
          })
        );

        try {
          await lifecycleApi.queryFulfilled;
          //          const state = lifecycleApi.getState();
          //        const updatedAsset = state.assetApi.queries['getAssets']?.data?.entities[assetId];

          //      toast.success(`Asset ${updatedAsset.status === 'active' ? 'restored' : 'archived'} successfully`);
          toast.success(`Asset updated`);
        } catch (error) {
          console.error('Error archiving asset', error);
          toast.error('Error archiving asset');
          getAssetsPatchResult.undo();
        }
      },
      // invalidatesTags: ['Asset'],
    }),

    getAssetLocations: builder.query({
      query: (assetId) => `asset/${assetId}/locations`,
    }),
    addCollaborator: builder.mutation({
      query: ({ assetId, ...collaborator }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'POST',
        body: collaborator,
      }),
      invalidatesTags: ['Asset'],
    }),
    removeCollaborator: builder.mutation({
      query: ({ assetId, userId }) => ({
        url: `asset/${assetId}/collaborators`,
        method: 'DELETE',
        body: { user_id: userId },
      }),
      invalidatesTags: ['Asset'],
    }),
    uploadCoverImage: builder.mutation({
      query: ({ assetId, coverImage }) => {
        const formData = new FormData();
        formData.append('cover_image', coverImage);
        return {
          url: `asset/${assetId}/cover`,
          method: 'POST',
          body: formData,
          headers: {
            // Explicitly remove Content-Type header if it's being set somewhere
            'Content-Type': undefined,
          },
        };
      },
      invalidatesTags: ['CoverImage'],
    }),
    getLoggedInUser: builder.query({
      query: () => 'user/profile',
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['User'],
    }),
    getTeamUsers: builder.query({
      query: () => 'users',
      transformResponse: (response: { users: any[] }) => response.users,
      providesTags: ['Users'],
    }),
    updateUserProfile: builder.mutation({
      query: (profile) => ({
        url: 'user/profile',
        method: 'PUT',
        body: profile,
      }),
      invalidatesTags: ['User'],
    }),
    deleteCoverImage: builder.mutation({
      query: (assetId) => ({
        url: `asset/${assetId}/cover`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CoverImage'],
    }),
    // getAssetAssessments: builder.query({
    //   query: (assetId) => `asset/${assetId}/assessments`,
    //   providesTags: ['Assessment'],
    // }),
    getAssetBuildings: builder.query({
      query: (assetId) => `asset/${assetId}/buildings`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((building) => ({
                type: 'Building' as const,
                id: building.id,
              })),
              { type: 'Building', id: 'LIST' },
            ]
          : [{ type: 'Building', id: 'LIST' }],
    }),
    addBuilding: builder.mutation({
      query: (building) => ({
        url: `buildings`,
        method: 'POST',
        body: building,
      }),
      invalidatesTags: (result, error, { asset_id }) => [
        { type: 'Building', id: 'LIST' },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateBuilding: builder.mutation({
      query: ({ building_id, ...building }) => ({
        url: `buildings/${building_id}`,
        method: 'PUT',
        body: building,
      }),
      invalidatesTags: (result, error, { id, asset_id }) => [
        { type: 'Building', id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    getAssetLevels: builder.query({
      query: (assetId) => `asset/${assetId}/levels`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((level) => ({
                type: 'Level' as const,
                id: level.id,
              })),
              { type: 'Level', id: 'LIST' },
            ]
          : [{ type: 'Level', id: 'LIST' }],
    }),

    addAssetLevel: builder.mutation({
      query: (level) => ({
        url: `levels`,
        method: 'POST',
        body: level,
      }),
      invalidatesTags: (result, error, { asset_id, building_id }) => [
        { type: 'Level', id: 'LIST' },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateLevel: builder.mutation({
      query: ({ level_id, ...level }) => ({
        url: `levels/${level_id}`,
        method: 'PUT',
        body: level,
      }),
      invalidatesTags: (result, error, { id, asset_id, building_id }) => [
        { type: 'Level', id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    getAssetAreas: builder.query({
      query: (assetId) => `asset/${assetId}/areas`,
      providesTags: (result) =>
        result
          ? [
              ...result.map((area) => ({ type: 'Area' as const, id: area.id })),
              { type: 'Area', id: 'LIST' },
            ]
          : [{ type: 'Area', id: 'LIST' }],
    }),
    addAssetArea: builder.mutation({
      query: (area) => ({
        url: `rooms`,
        method: 'POST',
        body: area,
      }),
      invalidatesTags: (result, error, { asset_id, building_id, level_id }) => [
        { type: 'Area', id: 'LIST' },
        { type: 'Level', id: level_id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),
    updateArea: builder.mutation({
      query: ({ room_id, ...area }) => ({
        url: `rooms/${room_id}`,
        method: 'PUT',
        body: area,
      }),
      invalidatesTags: (
        result,
        error,
        { room_id, asset_id, building_id, level_id }
      ) => [
        { type: 'Area', id: room_id },
        { type: 'Level', id: level_id },
        { type: 'Building', id: building_id },
        { type: 'Asset', id: asset_id },
      ],
    }),

    getUserQualifications: builder.query({
      query: () => `qualifications`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Qualifications'],
    }),
    createUserQualification: builder.mutation({
      query: (qualification) => ({
        url: `qualifications`,
        method: 'POST',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    updateUserQualification: builder.mutation({
      query: ({ qualification_id, ...qualification }) => ({
        url: `qualifications/${qualification_id}`,
        method: 'PUT',
        body: qualification,
      }),
      invalidatesTags: ['Qualifications'],
    }),
    deleteUserQualification: builder.mutation({
      query: (qualification_id) => ({
        url: `qualifications/${qualification_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Qualifications'],
    }),

    getOffices: builder.query({
      query: () => 'offices',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Offices'],
    }),

    createOffice: builder.mutation({
      query: (office) => ({
        url: 'offices',
        method: 'POST',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    deleteOffice: builder.mutation({
      query: (officeId) => ({
        url: `offices/${officeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Offices'],
    }),

    updateOffice: builder.mutation({
      query: ({ id, ...office }) => ({
        url: `offices/${id}`,
        method: 'PUT',
        body: office,
      }),
      invalidatesTags: ['Offices'],
    }),

    getAdminOrganisations: builder.query({
      query: () => 'admin/organisations',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Organisations'],
    }),
    getAdminUsers: builder.query({
      query: (organisationId) =>
        organisationId ? `admin/users/${organisationId}` : 'admin/users',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['Users'],
    }),

    deleteAdminUser: builder.mutation({
      query: ({ userId }) => ({
        url: `admin/users/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),

    getOrganisation: builder.query({
      query: (organisationId) => `organisations/${organisationId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['Organisations'],
    }),

    updateOrganisation: builder.mutation({
      query: ({ id, ...organisation }) => ({
        url: `organisations/${id}`,
        method: 'PUT',
        body: organisation,
      }),
      invalidatesTags: ['Organisations'],
    }),

    leaveOrganisation: builder.mutation({
      query: ({ id, user_id }) => ({
        url: `organisations/${id}/leave`,
        method: 'DELETE',
        body: {
          user_id: user_id,
        },
      }),
      invalidatesTags: [
        'Organisations',
        'Users',
        'Offices',
        'Asset',
        'Buildings',
        'Levels',
        'Areas',
        'User',
      ],
    }),

    inviteTeamMember: builder.mutation({
      query: (user) => ({
        url: 'organisations/invite',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    createUser: builder.mutation({
      query: (user) => ({
        url: 'register',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),

    updateUserRole: builder.mutation({
      query: ({ user_id, organisation_id, role }) => ({
        url: `users/${user_id}/organisations/${organisation_id}`,
        method: 'PUT',
        body: { role },
      }),
      invalidatesTags: ['Users'],
    }),

    getAllElementGroups: builder.query({
      query: (organisationId) =>
        `classification-trees/organisation/${organisationId}`,
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    getElementGroup: builder.query({
      query: (elementGroupId) => `classification-trees/${elementGroupId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: ['ElementGroups'],
    }),
    getElementGroups: builder.query<Record<number, any>, number[]>({
      queryFn: async (elementGroupIds, _queryApi, _extraOptions, baseQuery) => {
        try {
          const results = await Promise.all(
            elementGroupIds.map((groupId) =>
              baseQuery(`classification-trees/${groupId}`)
            )
          );

          // Check for errors
          const errors = results.filter((result) => result.error);
          if (errors.length > 0) {
            return { error: { status: 'CUSTOM_ERROR', data: errors } };
          }

          // Transform responses into a mapped object
          const transformedData = results.reduce((acc, result, index) => {
            if (result.data) {
              //@ts-expect-error - elementGroupIds is an array of numbers
              acc[elementGroupIds[index]] = result.data.data;
            }
            return acc;
          }, {} as Record<number, any>);

          return { data: transformedData };
        } catch (error) {
          return { error: { status: 'FETCH_ERROR', error: String(error) } };
        }
      },
      providesTags: (result) => [
        'ElementGroups',
        ...(result
          ? Object.keys(result).map((id) => ({
              type: 'ElementGroups' as const,
              id: Number(id),
            }))
          : []),
      ],
    }),
    createElementGroup: builder.mutation({
      query: (elementGroup) => ({
        url: 'classification-trees',
        method: 'POST',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),
    updateElementGroup: builder.mutation({
      query: ({ id, ...elementGroup }) => ({
        url: `classification-trees/${id}`,
        method: 'PUT',
        body: elementGroup,
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    getElementTree: builder.query<ClassificationItem[], number>({
      query: (treeId) => `classification-trees/${treeId}/items`,
      transformResponse: (response: ClassificationItem[]) =>
        transformClassificationTree.buildTree(response),
      providesTags: (result, error, treeId) => [
        { type: 'ElementTree', id: treeId },
        ...(result
          ?.flatMap((item) => getAllItemIds(item))
          .map((id) => ({ type: 'ElementItem' as const, id })) ?? []),
      ],
    }),

    getElementTrees: builder.query<
      Record<number, ClassificationItem[]>,
      number[]
    >({
      queryFn: async (
        treeIds: number[],
        _queryApi,
        _extraOptions,
        baseQuery
      ) => {
        try {
          // Fetch all trees in parallel
          const results = await Promise.all(
            treeIds.map((treeId) =>
              baseQuery(`classification-trees/${treeId}/items`)
            )
          );

          // Check for any errors
          const errors = results.filter((result) => result.error);
          if (errors.length > 0) {
            return { error: { status: 'CUSTOM_ERROR', data: errors } };
          }

          // Transform the results into a mapped object
          const transformedData = results.reduce((acc, result, index) => {
            if (result.data) {
              acc[treeIds[index]] = transformClassificationTree.buildTree(
                result.data as ClassificationItem[]
              );
            }
            return acc;
          }, {} as Record<number, ClassificationItem[]>);

          return { data: transformedData };
        } catch (error) {
          return { error: { status: 'FETCH_ERROR', error: String(error) } };
        }
      },
      providesTags: (result, error, treeIds) => [
        // Provide tags for each tree
        ...treeIds.map((treeId) => ({
          type: 'ElementTree' as const,
          id: treeId,
        })),
        // Provide tags for all items in all trees
        ...(result
          ? Object.values(result).flatMap((tree) =>
              tree
                .flatMap((item) => getAllItemIds(item))
                .map((id) => ({ type: 'ElementItem' as const, id }))
            )
          : []),
      ],
    }),

    archiveElementTreeForUser: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/status`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ElementTree', id },
        { type: 'ElementGroups', id: 'LIST' },
      ],
    }),

    // Archive Classification Tree Mutation
    archiveElementTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ElementTree', id },
        { type: 'ElementGroups', id: 'LIST' },
      ],
    }),

    duplicateClassificationTree: builder.mutation({
      query: (classificationTreeId) => ({
        url: `classification-trees/${classificationTreeId}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ElementGroups'],
    }),

    //POST classification-trees/{classificationTree}/items
    createClassificationItem: builder.mutation({
      query: ({ classification_tree_id, ...item }) => ({
        url: `classification-trees/${classification_tree_id}/items`,
        method: 'POST',
        body: item,
      }),
      invalidatesTags: (result, error, { classification_tree_id }) => [
        { type: 'ElementTree', id: classification_tree_id },
        { type: 'ElementGroups', id: classification_tree_id },
      ],
    }),
    // put classification-items/{classificationItem}
    updateClassificationItem: builder.mutation({
      query: ({ classificationItem, ...item }) => ({
        url: `classification-items/${classificationItem}`,
        method: 'PUT',
        body: item,
      }),
      invalidatesTags: (result, error, { classificationItem }) => [
        { type: 'ElementItem', id: classificationItem },
      ],
    }),

    // Observations Endpoints
    addObservation: builder.mutation({
      query: ({ classificationItem, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: observation,
      }),
      invalidatesTags: ['Observations'],
    }),
    getObservations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Observations'],
    }),
    updateObservation: builder.mutation({
      query: ({ classificationItem, comment, observation }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'PUT',
        body: observation,
      }),
      invalidatesTags: ['Observations'],
    }),
    deleteObservation: builder.mutation({
      query: ({ classificationItem, comment }) => ({
        url: `classification-trees/element/${classificationItem}/observations/${comment}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Observations'],
    }),

    // Recommendations Endpoints
    addRecommendation: builder.mutation({
      query: ({ classificationItem, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: recommendation,
      }),
      invalidatesTags: ['Recommendations'],
    }),
    getRecommendations: builder.query({
      query: ({ classificationItem, page = 1, perPage = 10 }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        params: { page, per_page: perPage },
      }),
      providesTags: ['Recommendations'],
    }),
    updateRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId, recommendation }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'PUT',
        body: recommendation,
      }),
      invalidatesTags: ['Recommendations'],
    }),
    deleteRecommendation: builder.mutation({
      query: ({ classificationItem, recommendationId }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations/${recommendationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recommendations'],
    }),

    // Observations Mutations
    handleObservations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/observations`,
        method: 'POST',
        body: { observations: rest.observations },
      }),
      invalidatesTags: ['Observations'],
    }),

    // Recommendations Mutations
    handleRecommendations: builder.mutation({
      query: ({ classificationItem, ...rest }) => ({
        url: `classification-trees/element/${classificationItem}/recommendations`,
        method: 'POST',
        body: { recommendations: rest.recommendations },
      }),
      invalidatesTags: ['Recommendations'],
    }),

    getTestSuites: builder.query({
      query: () => 'test-suites',
      transformResponse: (response: { data: any[] }) =>
        response.data.map((suite: any) => ({
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          // status: suite.status === 'inuse' ? 'active' : 'archived',
        })),
      providesTags: (result) =>
        result
          ? [
              // Provide a tag for the entire list
              { type: 'TestSuites', id: 'LIST' },
              // Provide individual tags for each test suite
              ...result.map((suite) => ({
                type: 'TestSuites' as const,
                id: suite.id,
              })),
              // If the suites include specifications, tag them too
              ...result.flatMap(
                (suite) =>
                  suite.specifications?.map((spec) => ({
                    type: 'Specifications' as const,
                    id: spec.id,
                  })) || []
              ),
            ]
          : [{ type: 'TestSuites', id: 'LIST' }],
    }),
    getTestSuite: builder.query({
      query: (testSuiteId) => 'test-suites/' + testSuiteId,
      transformResponse: (response: { data: any }) => {
        const suite = response.data;
        return {
          ...suite,
          inspections: suite.total_assessments,
          categories: suite.total_categories,
          subCategories: suite.total_specifications,
          tests: suite.total_specifications,
          //status: suite.status === 'archived' ? 'archived' : 'active',
        };
      },
      providesTags: (result, error, id) =>
        result
          ? [
              // Tag for the specific test suite
              { type: 'TestSuites', id },
              // Tag for the list of test suites
              { type: 'TestSuites', id: 'LIST' },
              // Tags for specifications if they exist
              ...(result.specifications?.map((spec) => ({
                type: 'Specifications' as const,
                id: spec.id,
              })) || []),
              // Tag for the specifications list of this suite
              { type: 'Specifications', id: `${id}-LIST` },
            ]
          : [
              { type: 'TestSuites', id },
              { type: 'TestSuites', id: 'LIST' },
            ],
    }),
    getMultipleTestSuites: builder.query<TestSuite[], number[]>({
      queryFn: async (ids, api, extraOptions, baseQuery) => {
        try {
          const requests = ids.map(async (id) => {
            const result = await baseQuery(`test-suites/${id}`);
            if (result.error) {
              throw result.error;
            }
            return (result.data as { data: TestSuite }).data;
          });

          const results = await Promise.all(requests);
          return { data: results };
        } catch (error) {
          return { error: error as any };
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((suite) => ({
                type: 'TestSuites' as const,
                id: suite.id,
              })),
              { type: 'TestSuites', id: 'LIST' },
            ]
          : [{ type: 'TestSuites', id: 'LIST' }],

      // Optional: Add merge strategy for concurrent requests
      merge: (currentCache, newItems) => {
        // Merge logic if needed
        return [...newItems];
      },

      // Optional: Add serialization strategy for cache key
      serializeQueryArgs: ({ queryArgs }) => {
        return queryArgs.sort().join(',');
      },
    }),
    createTestSuite: builder.mutation({
      query: (newTestSuite) => ({
        url: 'test-suites',
        method: 'POST',
        body: newTestSuite,
      }),
      invalidatesTags: [{ type: 'TestSuites', id: 'LIST' }],
    }),
    updateTestSuite: builder.mutation({
      query: ({ id, ...testSuite }) => ({
        url: `test-suites/${id}`,
        method: 'PUT',
        body: testSuite,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'TestSuites', id },
        { type: 'TestSuites', id: 'LIST' },
      ],
    }),

    updateTestSuiteFields: builder.mutation({
      query: ({ id, ...fields }) => ({
        url: `test-suites/${id}/fields`,
        method: 'PUT',
        body: fields,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'TestSuites', id },
        { type: 'TestSuites', id: 'LIST' },
      ],
    }),

    duplicateTestSuite: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: [{ type: 'TestSuites', id: 'LIST' }],
    }),

    getTestSpecifications: builder.query({
      query: (testSuiteId) => `test-suites/${testSuiteId}/tests`,
      transformResponse: (response: { data: any[] }) => response.data,
      // providesTags: (result) =>
      //   result
      //     ? [
      //         ...result.map((spec) => ({
      //           type: 'Specifications' as const,
      //           id: spec.id,
      //         })),
      //         { type: 'Specifications', id: 'LIST' },
      //       ]
      //     : [{ type: 'Specifications', id: 'LIST' }],
      providesTags: (result) => [
        ...(result?.map((spec) => ({
          type: 'Specifications' as const,
          id: spec.id,
        })) || []),
        { type: 'Specifications', id: 'LIST' },
        { type: 'TestSuites', id: 'LIST' },
      ],
    }),

    updateTestSpecification: builder.mutation<
      TestSpecificationsEntity,
      {
        testSuiteId: number;
        testSpecificationId: number;
        test: Partial<TestSpecificationsEntity>;
      }
    >({
      query: ({ testSuiteId, testSpecificationId, test }) => ({
        url: `test-suites/${testSuiteId}/tests/${testSpecificationId}`,
        method: 'PUT',
        body: test,
      }),
      invalidatesTags: (
        result,
        error,
        { testSuiteId, testSpecificationId }
      ) => [{ type: 'Specifications', id: testSpecificationId }],
    }),

    createTestSpecification: builder.mutation<
      TestSpecificationsEntity,
      { testSuiteId: number; test: Partial<TestSpecificationsEntity> }
    >({
      query: ({ testSuiteId, test }) => ({
        url: `test-suites/${testSuiteId}/tests`,
        method: 'POST',
        body: test,
      }),
      invalidatesTags: [{ type: 'Specifications', id: 'LIST' }],
    }),

    deleteTestSpecification: builder.mutation<
      void,
      { testSuiteId: number; testSpecificationId: number }
    >({
      query: ({ testSuiteId, testSpecificationId }) => ({
        url: `test-suites/${testSuiteId}/tests/${testSpecificationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Specifications', id: 'LIST' }],
    }),

    renameTestSpecificationCategory: builder.mutation<
      void,
      {
        testSuiteId: string;
        new_category: string;
        old_category: string;
      }
    >({
      query: ({ testSuiteId, new_category, old_category }) => ({
        url: `test-suites/${testSuiteId}/categories`,
        method: 'PUT',
        body: { new_category, old_category },
      }),
      invalidatesTags: ['Specifications'],
    }),

    renameTestSpecificationSubCategory: builder.mutation<
      void,
      {
        testSuiteId: string;
        category: string;
        old_subcategory: string;
        new_subcategory: string;
      }
    >({
      query: ({ testSuiteId, category, old_subcategory, new_subcategory }) => ({
        url: `test-suites/${testSuiteId}/subcategories`,
        method: 'PUT',
        body: { category, old_subcategory, new_subcategory },
      }),
      invalidatesTags: ['Specifications'],
    }),

    addTestSpecificationFastFill: builder.mutation<
      any,
      { testSpecificationId: number; data: any }
    >({
      query: ({ testSpecificationId, data }) => ({
        url: `/test-suites/tests/${testSpecificationId}/fast-fill`,
        method: 'POST',
        body: data,
      }),
      async onQueryStarted(
        { testSpecificationId, data },
        { dispatch, queryFulfilled }
      ) {
        // Get the current cache key for the test specifications
        const patchResult = dispatch(
          rAPI.util.updateQueryData(
            'getTestSpecifications',
            testSpecificationId,
            (draft) => {
              const specification = draft.find(
                (spec) => spec.id === testSpecificationId
              );
              if (specification) {
                // Update the fast fills
                console.debug(
                  'Updating fast fills',
                  specification,
                  specification.fastFills,
                  data
                );
                specification.fastFills = [...specification.fastFills, data];
              }
            }
          )
        );

        try {
          await queryFulfilled;
        } catch {
          // Undo the optimistic update on error
          patchResult.undo();
        }
      },
      // Optionally invalidate related queries after the mutation
      invalidatesTags: (result, error, { testSpecificationId }) => [
        { type: 'Specifications', id: testSpecificationId },
      ],
    }),

    getRatingScales: builder.query({
      query: () => 'rating-scales',
      transformResponse: (response: { data: any[] }) => response.data,
      providesTags: ['RatingScales'],
    }),

    getTestItem: builder.query({
      query: (testItemId) => `tests/${testItemId}`,
      transformResponse: (response: { data: any }) => response.data,
      providesTags: (result) => {
        return result
          ? [
              { type: 'TestItem' as const, id: result.assessmentTestId },
              { type: 'TestItem', id: 'LIST' },
            ]
          : [{ type: 'TestItem', id: 'LIST' }];
      },
    }),

    addCustomFieldOption: builder.mutation<
      AddCustomFieldOptionResponse,
      AddCustomFieldOptionRequest
    >({
      query: ({ testSuiteId, ...body }) => ({
        url: `test-suites/${testSuiteId}/add-field-option`,
        method: 'POST',
        body,
      }),
      // Optionally invalidate the test suite cache when a new option is added
      invalidatesTags: (result, error, { testSuiteId }) => [
        { type: 'TestSuites', id: testSuiteId },
      ],
    }),

    // getTestItems: builder.query<EntityState<TestItem, number>, number>({
    //   query: (assessmentId) => `assessments/${assessmentId}/test-items`,
    //   //transformResponse: (response: {data: TestItem[]}) => response.data,
    //   transformResponse: (response: { data: TestItem[] }) => {
    //     // Transform the response into a normalized state using the adapter
    //     return testItemsAdapter.setAll(
    //       testItemsAdapter.getInitialState(),
    //       response.data
    //     );
    //   },
    //   providesTags: (result) =>
    //     result
    //       ? [
    //           ...result.ids.map((id) => ({ type: 'TestItems' as const, id })),
    //           { type: 'TestItems' as const, id: 'LIST' },
    //         ]
    //       : [{ type: 'TestItems' as const, id: 'LIST' }],
    // }),

    // getTestItemById: builder.query({
    //   query: (id) => `tests/${id}`,
    //   transformResponse: (response: { data: TestItem }) => {
    //     // Normalize the single item response using the adapter
    //     return testItemsAdapter.upsertOne(
    //       testItemsAdapter.getInitialState(),
    //       response.data
    //     );
    //   },
    //   providesTags: (result, error, id) => [{ type: 'TestItems', id }],
    // }),

    archiveTestSuiteForUser: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['TestSuites'],
    }),

    archiveTestSuite: builder.mutation({
      query: (id) => ({
        url: `test-suites/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['TestSuites'],
    }),

    // getAssessment: builder.query<Assessment, number>({
    //   query: (assessmentId) => `assessments/${assessmentId}`,
    //   transformResponse: (response: { data: Assessment }) => response.data,
    //   providesTags: ['Assessment'],
    // }),

    getReportTestSuites: builder.query<TestSuite[], number>({
      query: (reportId) => `reports/${reportId}/test-suites`,
      transformResponse: (response: { data: TestSuite[] }) => response.data,
      providesTags: (result) => {
        if (!result) return [{ type: 'TestSuites', id: 'LIST' }];

        return [
          // Add tags for each test suite
          ...result.map((suite) => ({
            type: 'TestSuites' as const,
            id: suite.id,
          })),
          { type: 'TestSuites', id: 'LIST' },
          // Add tags for specifications in each test suite
          ...result.flatMap(
            (suite) =>
              suite.specifications?.map((spec) => ({
                type: 'Specifications' as const,
                id: spec.id,
              })) || []
          ),
          { type: 'Specifications', id: 'LIST' },
        ];
      },
    }),

    getReportItems: builder.query<ReportTestItem[], string>({
      query: (reportId) => `reports/${reportId}/test-items`,
      transformResponse: (response: { data: ReportTestItem[] }) =>
        response.data,
      providesTags: (result, error, reportId) =>
        result
          ? [
              ...result.map(({ assessmentTestId }) => ({
                type: 'TestItem' as const,
                id: assessmentTestId,
              })),
              { type: 'TestItems', id: reportId },
              { type: 'TestItems', id: 'LIST' },
            ]
          : [
              { type: 'TestItems', id: reportId },
              { type: 'TestItems', id: 'LIST' },
            ],
    }),

    getSingleReportItem: builder.query<
      ReportTestItem,
      { reportId: string; testItemId: number }
    >({
      query: ({ reportId, testItemId }) =>
        `reports/${reportId}/test-items/${testItemId}`,
      transformResponse: (response: { data: ReportTestItem }) => response.data,
      providesTags: (result) =>
        result
          ? [
              {
                type: 'TestItem' as const,
                id: result.assessmentTestId,
                reportId: result.reportId,
              },
            ]
          : [],
      async onQueryStarted(
        { reportId, testItemId },
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          const { data: updatedItem } = await queryFulfilled;

          // Get current cache state
          const currentData = rAPI.endpoints.getReportItems.select(reportId)(
            getState()
          )?.data;

          if (!currentData) return;

          const existingItem = currentData.find(
            (item) => item.assessmentTestId === testItemId
          );
          if (
            !existingItem ||
            JSON.stringify(existingItem) !== JSON.stringify(updatedItem)
          ) {
            // Create new array with updated item
            const updatedData = currentData.map((item) =>
              item.assessmentTestId === testItemId ? updatedItem : item
            );

            // Upsert the entire updated array
            dispatch(
              rAPI.util.updateQueryData('getReportItems', reportId, (draft) => {
                // Find the item in the draft and replace it
                const itemIndex = draft.findIndex(
                  (item) => item.assessmentTestId === testItemId
                );
                if (itemIndex !== -1) {
                  draft[itemIndex] = updatedItem;
                }
              })
            );
          }
        } catch (error) {
          // Handle error if needed
          console.error('Failed to update report items cache:', error);
        }
      },
    }),

    toggleTestSwitch: builder.mutation<
      ToggleSwitchResponse,
      ToggleSwitchRequest
    >({
      query: ({ reportId, revisionId, testItemId, switchName }) => ({
        url: `revisions/${revisionId}/test-items/${testItemId}/toggle/${switchName}`,
        method: 'POST',
      }),
      async onQueryStarted(
        { reportId, revisionId, testItemId, switchName },
        { dispatch, queryFulfilled, getState }
      ) {
        const patchResult = updateItemInReportCache(
          dispatch,
          getState,
          String(reportId),
          Number(testItemId),
          (item) => {
            // Create a new item with the switch toggled
            console.debug('toggleTestSwitch returning switch from - to', {
              switchName,
              from: item.switches[switchName],
              to: !item.switches[switchName],
            });

            return {
              ...item,
              switches: {
                ...item.switches,
                [switchName]: !item.switches[switchName],
              },
            };
          }
        );

        try {
          // Wait for the API response
          const { data } = await queryFulfilled;

          console.debug('toggleTestSwitch', { data });

          const currentData = rAPI.endpoints.getReportItems.select(
            String(reportId)
          )(getState())?.data;
          if (currentData) {
            console.debug('toggleTestSwitch upserting currentData', {
              currentData,
              reportId,
            });
            dispatch(
              rAPI.util.upsertQueryData('getReportItems', String(reportId), [
                ...currentData,
              ])
            );
          }

          // If needed, you could update with the server response data here
          // This is optional if the optimistic update is sufficient
        } catch {
          // Revert all optimistic updates on error
          patchResult.undo();
        }
      },
    }),
    createTestItem: builder.mutation({
      query: (newTestItem) => ({
        url: 'tests',
        method: 'POST',
        body: newTestItem,
      }),
      invalidatesTags: (result, error, { assessmentTestId, assessmentId }) => [
        { type: 'TestItems', id: assessmentId },
        { type: 'TestItems', id: 'LIST' },
        { type: 'TestItem', id: assessmentTestId },
        { type: 'TestItem', id: 'LIST' },
      ],
    }),

    updateTestItem: builder.mutation<TestItem, Partial<TestItem>>({
      query: ({ assessmentTestId, ...updatedItem }) => ({
        url: `tests/${assessmentTestId}`,
        method: 'PUT',
        body: updatedItem,
      }),
      invalidatesTags: (result, error, { assessmentTestId, assessmentId }) => [
        { type: 'TestItems', id: assessmentTestId },
        { type: 'TestItems', id: assessmentId },
        { type: 'TestItem', id: assessmentTestId },
        { type: 'TestItem', id: 'LIST' },
      ],
    }),

    updateReportTestItem: builder.mutation<
      ReportTestItem,
      Partial<TestItem & { reportId: number }>
    >({
      query: ({ reportId, assessmentTestId, ...updatedItem }) => ({
        url: `reports/${reportId}/test-items/${assessmentTestId}`,
        method: 'PUT',
        body: updatedItem,
      }),
      invalidatesTags: (result, error, { assessmentTestId }) => [
        { type: 'TestItem', id: assessmentTestId },
        { type: 'TestItem', id: 'LIST' },
      ],
      async onQueryStarted(
        { reportId, assessmentTestId, assessmentId, ...patch },
        { dispatch, queryFulfilled }
      ) {
        // Optimistically update the getReportItems cache
        const patchResult = dispatch(
          rAPI.util.updateQueryData(
            'getReportItems',
            String(reportId),
            (draft: ReportTestItem[]) => {
              const itemToUpdate = draft.find(
                (item) => item.assessmentTestId === assessmentTestId
              );
              if (itemToUpdate) {
                Object.assign(itemToUpdate, patch);
              }
            }
          )
        );

        try {
          const { data: updatedItem } = await queryFulfilled;

          // Optional: Update with server response if needed
          dispatch(
            rAPI.util.updateQueryData(
              'getReportItems',
              String(reportId),
              (draft: ReportTestItem[]) => {
                const itemToUpdate = draft.find(
                  (item) => item.assessmentTestId === assessmentTestId
                );
                if (itemToUpdate) {
                  Object.assign(itemToUpdate, updatedItem);
                }
              }
            )
          );
        } catch {
          // Undo the optimistic update on error
          patchResult.undo();
        }
      },
    }),

    getReportBlocks: builder.query<ReportBlock[], void>({
      query: () => 'report-blocks',
      transformResponse: (response: { data: ReportBlock[] }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Fetch a single ReportBlock by ID
    getReportBlock: builder.query<ReportBlock, string>({
      query: (id) => `report-blocks/${id}`,
      transformResponse: (response: { data: ReportBlock }) => response.data,
      providesTags: ['ReportBlocks'],
    }),
    // Create a new ReportBlock

    createReportBlock: builder.mutation({
      query: (newReportBlock) => ({
        url: 'report-blocks',
        method: 'POST',
        body: newReportBlock,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Update an existing ReportBlock
    updateReportBlock: builder.mutation({
      query: ({ id, ...block }) => ({
        url: `report-blocks/${id}`,
        method: 'PUT',
        body: block,
      }),
      invalidatesTags: ['ReportBlocks'],
    }),
    // Delete a ReportBlock
    deleteReportBlock: builder.mutation<{ success: boolean }, number>({
      query: (id) => ({
        url: `report-blocks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    archiveReportBlockForUser: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    // Archive Classification Tree Mutation
    archiveReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    duplicateReportBlock: builder.mutation({
      query: (id) => ({
        url: `report-blocks/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['ReportBlocks'],
    }),

    getInspections: builder.query<Assessment[], void>({
      query: () => 'assessments',
      providesTags: [{ type: 'Assessments', id: 'LIST' }],
    }),

    getReportInspections: builder.query<Assessment[], number>({
      query: (reportId) => `reports/${reportId}/assessments`,
      transformResponse: (response: { data: Assessment[] }) => response.data,
      providesTags: (result, error, reportId) => [
        { type: 'Assessment', id: reportId },
        'Assessment',
      ],
      keepUnusedDataFor: 60, // Cache lifetime in seconds
    }),

    getInspection: builder.query<Assessment, string>({
      query: (id) => `assessments/${id}`,
      transformResponse: (response: { data: Assessment }) => response.data,
      providesTags: (result, error, id) => [{ type: 'Assessment', id: id }],
    }),

    archiveInspection: builder.mutation({
      query: (assessmentId) => ({
        url: `assessments/${assessmentId}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, assessmentId) => [
        { type: 'Assessments', id: 'LIST' },
        { type: 'Assessment', id: assessmentId },
      ],
    }),

    archiveInspectionForUser: builder.mutation({
      query: (assessmentId) => ({
        url: `assessments/${assessmentId}/status`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, assessmentId) => [
        { type: 'Assessments', id: 'LIST' },
        { type: 'Assessment', id: assessmentId },
      ],
    }),

    updateInspection: builder.mutation({
      query: ({ id, ...inspection }) => ({
        url: `assessments/${id}`,
        method: 'PUT',
        body: inspection,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Assessments', id: 'LIST' },
        { type: 'Assessment', id: id },
      ],
    }),

    createInspection: builder.mutation({
      query: (inspection) => ({
        url: 'assessments',
        method: 'POST',
        body: inspection,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Assessments', id: 'LIST' },
      ],
    }),

    getReports: builder.query<ReportEntity[], void>({
      query: () => 'reports',
      transformResponse: (response: { data: ReportEntity[] }) => response.data,
      providesTags: [{ type: 'Reports', id: 'LIST' }],
    }),
    getReport: builder.query<ReportEntity, string>({
      query: (id) => `reports/${id}`,
      transformResponse: (response: { data: ReportEntity }) => response.data,
      // providesTags: ['Reports'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //         { type: 'Report', id: result.id },
      //         { type: 'Reports', id: 'LIST' },
      //         ...result.sections.map((section) => ({
      //           type: 'ReportSection' as const,
      //           id: section.id,
      //         })),
      //       ]
      //     : [{ type: 'Report', id: 'LIST' }],
      providesTags: (result) => {
        if (!result) return [{ type: 'Report', id: 'LIST' }];

        return [
          { type: 'Report', id: result.id },
          { type: 'Reports', id: 'LIST' },
          // Provide tags for sections
          ...result.sections.map((section) => ({
            type: 'ReportSection' as const,
            id: section.id,
          })),
          // Provide tags for test suites and their specifications
          ...result.testSuites
            .map((suite) => [
              { type: 'TestSuites' as const, id: suite.id },
              ...(suite.specifications?.map((spec) => ({
                type: 'Specifications' as const,
                id: spec.id,
              })) || []),
            ])
            .flat(),
        ];
      },
    }),
    getReportByUuid: builder.query<any, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `report/view/${uuid}`,
      }),
      transformResponse: (response: {
        report: ReportEntity;
        revision: ReportRevision;
      }) => response,
      // providesTags: ['Reports'],
      // providesTags: (result) =>
      //   result
      //     ? [
      //         { type: 'Report', id: result.id },
      //         { type: 'Reports', id: 'LIST' },
      //         ...result.sections.map((section) => ({
      //           type: 'ReportSection' as const,
      //           id: section.id,
      //         })),
      //       ]
      //     : [{ type: 'Report', id: 'LIST' }],
      // providesTags: (result) => {
      //   if (!result) return [{ type: 'Report', id: 'LIST' }];

      //   return [
      //     { type: 'Report', id: result.id },
      //     { type: 'Reports', id: 'LIST' },
      //     // Provide tags for sections
      //     ...result.sections.map((section) => ({
      //       type: 'ReportSection' as const,
      //       id: section.id,
      //     })),
      //     // Provide tags for test suites and their specifications
      //     ...result.testSuites
      //       .map((suite) => [
      //         { type: 'TestSuites' as const, id: suite.id },
      //         ...(suite.specifications?.map((spec) => ({
      //           type: 'Specifications' as const,
      //           id: spec.id,
      //         })) || []),
      //       ])
      //       .flat(),
      //   ];
      // },
    }),
    createReport: builder.mutation<ReportResponse, ReportPayload>({
      query: (payload) => {
        // const formData = new FormData();

        // if (payload['logo']) {
        //   formData.append('logo', payload['logo']);
        // }

        // const data = JSON.stringify({
        //   ...payload,
        //   logo: undefined,
        // });

        // formData.append('payload', data);

        return {
          url: 'reports',
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'Reports', id: 'LIST' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            rAPI.util.updateQueryData('getReports', undefined, (draft) => {
              draft.push(data['data']);
            })
          );
        } catch {
          console.error('Error creating report');
        }
      },
    }),
    updateReport: builder.mutation<
      ReportEntity,
      Partial<ReportUpdatePayload> & Pick<ReportEntity, 'id'>
    >({
      query: ({ id, ...patch }) => ({
        url: `reports/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),
    deleteReport: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `reports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          rAPI.util.updateQueryData('getReports', undefined, (draft) => {
            const index = draft.findIndex((report) => report.id === id);
            if (index !== -1) draft.splice(index, 1);
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),

    archiveReport: builder.mutation({
      query: (id) => ({
        url: `reports/${id}/archive`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),

    archiveReportForUser: builder.mutation({
      query: (id) => ({
        url: `reports/${id}/status`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Report', id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),

    //api/v1/client

    getClients: builder.query({
      query: () => 'clients',
      transformResponse: (response: { data: any[] }) => response.data,
    }),
    getClient: builder.query({
      query: (clientId) => `client/${clientId}`,
    }),
    createClient: builder.mutation({
      query: (client) => ({
        url: 'client',
        method: 'POST',
        body: client,
      }),
    }),
    updateClient: builder.mutation({
      query: ({ id, ...client }) => ({
        url: `client/${id}`,
        method: 'PUT',
        body: client,
      }),
    }),
    deleteClient: builder.mutation({
      query: (clientId) => ({
        url: `client/${clientId}`,
        method: 'DELETE',
      }),
    }),

    getAuthors: builder.query<
      UsersEntity[],
      { assets: number[]; inspections: number[] }
    >({
      query: ({ assets, inspections }) => {
        const params = new URLSearchParams();
        assets.forEach((asset) => params.append('assets', asset.toString()));
        inspections.forEach((inspection) =>
          params.append('inspections', inspection.toString())
        );
        return {
          url: `authors?${params.toString()}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: UsersEntity[] }) => response.data,
    }),

    getReportRevisions: builder.query<
      // PaginatedResponse<ReportRevision>,
      ReportRevision[],
      { report_id?: number; status?: string; page?: number; per_page?: number }
    >({
      query: (params) => ({
        url: `report/${params.report_id}/revisions`,
        params,
      }),
      transformResponse: (response: { data: ReportRevision[] }) =>
        response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ReportRevision' as const,
                id,
              })),
              { type: 'ReportRevision', id: 'LIST' },
            ]
          : [{ type: 'ReportRevision', id: 'LIST' }],
    }),

    getReportRevision: builder.query<
      { report: ReportEntity; revision: ReportRevision },
      number
    >({
      query: (id) => `report/revisions/${id}`,
      transformResponse: (response: {
        report: ReportEntity;
        revision: ReportRevision;
      }) => response,
      providesTags: (result, error, id) => [{ type: 'ReportRevision', id }],
    }),

    createReportRevision: builder.mutation<
      ReportRevision,
      Pick<ReportRevision, 'report_id' | 'notes'>
    >({
      query: (body) => ({
        url: 'report/revisions',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { data: ReportRevision }) => response.data,
      invalidatesTags: (result, error, body) => [
        { type: 'ReportRevision', id: 'LIST' },
        { type: 'Report', id: body.report_id },
        { type: 'Reports', id: 'LIST' },
      ],
    }),

    updateReportRevision: builder.mutation<
      ReportRevision,
      Pick<ReportRevision, 'id' | 'notes'>
    >({
      query: ({ id, ...patch }) => ({
        url: `report/revisions/${id}`,
        method: 'PUT',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: 'ReportRevision', id },
        { type: 'ReportRevision', id: 'LIST' },
      ],
    }),

    deleteReportRevision: builder.mutation<void, number>({
      query: (id) => ({
        url: `report/revisions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ReportRevision', id },
        { type: 'ReportRevision', id: 'LIST' },
      ],
    }),

    // publishRevision: builder.mutation<ReportRevision, number>({
    //   query: (id) => ({
    //     url: `report/revisions/${id}/publish`,
    //     method: 'POST',
    //   }),
    //   invalidatesTags: (result, error, id) => [
    //     { type: 'ReportRevision', id },
    //     { type: 'ReportRevision', id: 'LIST' },
    //   ],
    // }),



    getReportSections: builder.query<ReportSection[], number>({
      query: (reportId) => `reports/${reportId}/sections`,
      providesTags: (result, error, reportId) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'ReportSection' as const,
                id,
              })),
              { type: 'ReportSections' as const, id: reportId },
            ]
          : [{ type: 'ReportSections' as const, id: reportId }],
    }),
    getReportSection: builder.query<
      ReportSection,
      { reportId: number; sectionId: string }
    >({
      query: ({ reportId, sectionId }) =>
        `reports/${reportId}/sections/${sectionId}`,
      providesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
    }),
    createReportSection: builder.mutation<
      ReportSection,
      { reportId: number; data: Partial<ReportSection> }
    >({
      query: ({ reportId, data }) => ({
        url: `reports/${reportId}/sections`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, { reportId }) =>
        reportSectionTags(reportId),
    }),
    updateReportSection: builder.mutation<
      ReportSection,
      { reportId: number; sectionId: string; data: Partial<ReportSection> }
    >({
      query: ({ reportId, sectionId, data }) => ({
        url: `reports/${reportId}/sections/${sectionId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
      async onQueryStarted(
        { reportId, sectionId, data },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          rAPI.util.updateQueryData('getReportSections', reportId, (draft) => {
            const section = draft.find((s) => s.id === sectionId);
            if (section) {
              Object.assign(section, data);
            }
          })
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    }),
    deleteReportSection: builder.mutation<
      void,
      { reportId: number; sectionId: string }
    >({
      query: ({ reportId, sectionId }) => ({
        url: `reports/${reportId}/sections/${sectionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { reportId, sectionId }) =>
        reportSectionTags(reportId, sectionId),
    }),

    upsertReportSections: builder.mutation<
      ReportSection[],
      {
        reportId: number;
        sections: Array<Partial<ReportSection>>;
      }
    >({
      transformResponse: (response: { data: ReportSection[] }) => response.data,
      query: ({ reportId, sections }) => ({
        url: `reports/${reportId}/sections`,
        method: 'POST',
        body: { sections },
      }),
      invalidatesTags: (result, error, { reportId }) => [
        { type: 'Report', id: reportId.toString() },
      ],
    }),
    getPublishedReport: builder.query<PublishedReportResponse,number>({
      query: (revisionId) => `report/revisions/${revisionId}/published`,
      transformResponse: (response: PublishedReportResponse) => response,
      async onQueryStarted(revisionId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // Update test suites cache with the published report's test suites
          if (data.published.report?.testSuites) {
            dispatch(
              rAPI.util.upsertQueryData(
                'getReportTestSuites',
                Number(data.published.report?.id),
                data.published.report?.testSuites
              )
            );
          }
        } catch {
          // Handle error if needed
        }
      },
      providesTags: (result, error, revisionId) => [
        { type: 'ReportRevision', id: revisionId.toString() },
        { type: 'ReportRevision', id: 'LIST' },
      ],
    }),
    publishReport: builder.mutation<
      PublishReportResponse,
      { id: number; body: PublishReportPayload }
    >({
      query: ({ id, body }) => ({
        url: `report/${id}/publish`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { body }) => {
        // Skip cache invalidation if this is a dry run
        if (body.dry_run) return []
        
        return [
          { type: 'Report', id: result.revision.report_id },
          { type: 'Reports', id: 'LIST' },
          { type: 'ReportRevision', id: result.revision.id.toString() },
          { type: 'ReportRevision', id: 'LIST' },
        ]
      },
    }),

    //Add more mutations here
  }),

});

export const {
  useExtendAndResendInviteMutation,

  useGetAssetsQuery,
  useGetAssetQuery,
  useCreateAssetMutation,
  useUpdateAssetMutation,
  useRetireAssetMutation,
  useArchiveAssetMutation,
  useArchiveAssetForUserMutation,
  useGetAssetLocationsQuery,
  useAddCollaboratorMutation,
  useRemoveCollaboratorMutation,
  useUploadCoverImageMutation,
  useDeleteCoverImageMutation,
  // useGetAssetAssessmentsQuery,
  useGetAssetBuildingsQuery,
  useAddBuildingMutation,
  useUpdateBuildingMutation,
  useGetAssetLevelsQuery,
  useUpdateLevelMutation,
  useAddAssetLevelMutation,
  useGetAssetAreasQuery,
  useAddAssetAreaMutation,
  useUpdateAreaMutation,

  useGetLoggedInUserQuery,
  useGetTeamUsersQuery,
  useUpdateUserProfileMutation,

  useGetUserQualificationsQuery,
  useCreateUserQualificationMutation,
  useUpdateUserQualificationMutation,
  useDeleteUserQualificationMutation,

  useGetOfficesQuery,
  useCreateOfficeMutation,
  useDeleteOfficeMutation,
  useUpdateOfficeMutation,

  useGetOrganisationQuery,
  useUpdateOrganisationMutation,
  useLeaveOrganisationMutation,

  useInviteTeamMemberMutation,
  useCreateUserMutation,
  useUpdateUserRoleMutation,

  useGetAdminOrganisationsQuery,
  useGetAdminUsersQuery,
  useDeleteAdminUserMutation,
  useChangeUserRoleMutation,

  useGetAllElementGroupsQuery,
  useGetElementGroupQuery,
  useGetElementGroupsQuery,
  useCreateElementGroupMutation,
  useUpdateElementGroupMutation,
  useArchiveElementTreeForUserMutation,
  useArchiveElementTreeMutation,
  useDuplicateClassificationTreeMutation,

  useGetElementTreeQuery,
  useGetElementTreesQuery,
  useCreateClassificationItemMutation,
  useUpdateClassificationItemMutation,

  useAddObservationMutation,
  useGetObservationsQuery,
  useUpdateObservationMutation,
  useDeleteObservationMutation,

  useAddRecommendationMutation,
  useGetRecommendationsQuery,
  useUpdateRecommendationMutation,
  useDeleteRecommendationMutation,

  useHandleObservationsMutation,
  useHandleRecommendationsMutation,

  useAddCustomFieldOptionMutation,

  useGetTestSuitesQuery,
  useGetTestSuiteQuery,
  useGetMultipleTestSuitesQuery,
  useCreateTestSuiteMutation,
  useUpdateTestSuiteMutation,
  useUpdateTestSuiteFieldsMutation,

  useArchiveTestSuiteForUserMutation,
  useArchiveTestSuiteMutation,

  useDuplicateTestSuiteMutation,

  useGetTestSpecificationsQuery,
  useCreateTestSpecificationMutation,
  useUpdateTestSpecificationMutation,
  useDeleteTestSpecificationMutation,
  useRenameTestSpecificationCategoryMutation,
  useRenameTestSpecificationSubCategoryMutation,

  useAddTestSpecificationFastFillMutation,

  useGetRatingScalesQuery,

  useGetTestItemQuery,
  // useGetTestItemsQuery,
  // useGetAssessmentQuery,
  useCreateTestItemMutation,
  useUpdateTestItemMutation,

  useGetReportItemsQuery,
  useGetSingleReportItemQuery,
  useToggleTestSwitchMutation,

  useGetReportBlocksQuery,
  useGetReportBlockQuery,
  useCreateReportBlockMutation,
  useUpdateReportBlockMutation,
  useDeleteReportBlockMutation,

  useGetReportTestSuitesQuery,

  useArchiveReportBlockForUserMutation,
  useArchiveReportBlockMutation,
  useDuplicateReportBlockMutation,

  useGetInspectionsQuery,
  useArchiveInspectionMutation,
  useArchiveInspectionForUserMutation,
  useGetInspectionQuery,
  useUpdateInspectionMutation,
  useCreateInspectionMutation,

  useGetReportQuery,
  useGetReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useArchiveReportForUserMutation,
  useArchiveReportMutation,

  useGetClientsQuery,
  useGetClientQuery,
  useCreateClientMutation,
  useUpdateClientMutation,
  useDeleteClientMutation,

  useGetAuthorsQuery,

  useGetReportRevisionsQuery,
  useGetReportRevisionQuery,
  useCreateReportRevisionMutation,
  useUpdateReportRevisionMutation,
  useDeleteReportRevisionMutation,

  useGetReportSectionsQuery,
  useGetReportSectionQuery,
  useCreateReportSectionMutation,
  useUpdateReportSectionMutation,
  useDeleteReportSectionMutation,
  useUpsertReportSectionsMutation,

  useGetReportInspectionsQuery,

  useUpdateReportTestItemMutation,

  useGetPublishedReportQuery,
  usePublishReportMutation,
  useGetReportByUuidQuery,
  useLazyGetReportByUuidQuery,
} = rAPI;

export const selectAssetsResult = rAPI.endpoints.getAssets.select();

export const selectAssetsData = createSelector(
  selectAssetsResult,
  (result) => result?.data ?? initialState
);

export const { selectAll: selectAllAssets, selectById: selectAssetById } =
  assetsAdapter.getSelectors(selectAssetsData);
