import { rAPI } from './rAPI';
import { ShareReportResponse, ShareReportRequest, BulkIssueResponse } from './types';

// This returns the same API slice reference but with updated types
export const reportAPISlice = rAPI
  .enhanceEndpoints({
    addTagTypes: ['ReportIssue', 'ReportLink'],
  })
  .injectEndpoints({
    overrideExisting: false,
    // Add new tag types

    endpoints: (builder) => ({
      getReportIssues: builder.query({
        query: (revisionId) => `/report-revisions/${revisionId}/issues`,
        providesTags: ['ReportIssue'],
      }),
      addReportIssue: builder.mutation({
        query: ({ revisionId, issueData }) => ({
          url: `/report-revisions/${revisionId}/issues`,
          method: 'POST',
          body: issueData,
        }),
        invalidatesTags: ['ReportIssue'],
      }),
      updateReportIssue: builder.mutation({
        query: ({ revisionId, issueId, permissions }) => ({
          url: `/report-revisions/${revisionId}/issues/${issueId}`,
          method: 'PUT',
          body: permissions,
        }),
        invalidatesTags: ['ReportIssue'],
      }),
      deleteReportIssue: builder.mutation({
        query: ({ revisionId, issueId }) => ({
          url: `/report-revisions/${revisionId}/issues/${issueId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['ReportIssue'],
      }),

      // Shareable link endpoints
      createShareableLink: builder.mutation({
        query: ({ revisionId }: { revisionId: number }) => ({
          url: `/report-revisions/${revisionId}/links`,
          method: 'POST',
        }),
        invalidatesTags: ['ReportLink', { type: 'ReportRevision', id: 'LIST' }],
      }),
      toggleLinkStatus: builder.mutation({
        query: ({ revisionId, linkId, isEnabled, permissions }) => ({
          url: `/report-revisions/${revisionId}/links/${linkId}`,
          method: 'PUT',
          body: {
            is_enabled: isEnabled,
            allow_share: permissions?.allow_share,
            allow_export: permissions?.allow_export,
          },
        }),
        invalidatesTags: ['ReportLink', { type: 'ReportRevision', id: 'LIST' }],
      }),
      createTokenForReport: builder.mutation({
        query: ({ uuid, name, email }) => ({
          url: `/report/view/${uuid}`,
          method: 'POST',
          body: { name, email },
        }),
      }),
      bulkUpdateTestItems: builder.mutation({
        query: ({
          reportId,
          testItems,
          status,
        }: {
          reportId: number;
          testItems: number[];
          status: 'Draft' | 'Qa' | 'Published' | 'Archived';
        }) => ({
          url: `/reports/${reportId}/test-items/bulk-update`,
          method: 'PUT',
          body: { test_items: testItems, status },
        }),
        invalidatesTags: (result, error, { reportId, testItems, status }) => {
          return result
            ? [
                ...result.map(({ id, status, success }) => ({
                  type: 'TestItem' as const,
                  id: id,
                  reportId: reportId,
                })),
                { type: 'TestItems' as const, id: reportId },
                { type: 'TestItems' as const, id: 'LIST' },
              ]
            : [
                { type: 'TestItems' as const, id: reportId },
                { type: 'TestItems' as const, id: 'LIST' },
              ];
        },
      }),
      withdrawRevision: builder.mutation({
        query: ({ revision_id, reissue, replacement_id }: { revision_id: number; reissue: boolean; replacement_id: number }) => ({
          url: `report/revisions/${revision_id}/withdraw`,
          method: 'POST',
          body: { reissue, replacement_id },
        }),
        invalidatesTags: (result, error, { revision_id }) => [
          { type: 'ReportRevision', id: revision_id },
          { type: 'ReportRevision', id: 'LIST' },
          { type: 'Reports', id: 'LIST' },
        ],
      }),
      saveReportLink: builder.mutation({
        query: (revisionId: number) => ({
          url: `report-revisions/${revisionId}/save`,
          method: 'POST',
        }),
        async onQueryStarted(revisionId, { dispatch, queryFulfilled }) {
          // Optimistically update the getPublishedReport cache
          const patchResult = dispatch(
            rAPI.util.updateQueryData('getPublishedReport', revisionId, (draft) => {
              if (draft.permissions) {
                draft.permissions.isSaved = true
              }
            })
          )

          try {
            await queryFulfilled
          } catch {
            // If the mutation fails, revert the optimistic update
            patchResult.undo()
          }
        }
      }),
    
      // Remove from saved list
      removeSavedLink: builder.mutation({
        query: (revisionId: number) => ({
          url: `report-revisions/${revisionId}/save`,
          method: 'DELETE',
        }),
      }),
    
      // Share a report link with another user
      shareReportLink: builder.mutation({
        query: ({ 
          revisionId, 
          user_id, 
          allow_share, 
          allow_export 
        }: { 
          revisionId: number; 
          user_id: number; 
          allow_share: boolean; 
          allow_export: boolean; 
        }) => ({
          url: `report-revisions/${revisionId}/share`,
          method: 'POST',
          body: {
            user_id,
            allow_share,
            allow_export,
          },
        }),
      }),
    
      // Get user's saved report links
      getSavedLinks: builder.query({
        query: () => ({
          url: 'report-links/saved',
          method: 'GET',
        }),
        // providesTags: ['ReportLink'],
      }),

      shareReport: builder.mutation<ShareReportResponse, { revisionId: string; data: ShareReportRequest }>({
        query: ({ revisionId, data }) => ({
          url: `/reports/revisions/${revisionId}/share`,
          method: 'POST',
          body: data,
        }),
        // Invalidate relevant queries when a report is shared
        invalidatesTags: (result, error, { revisionId }) => [
          { type: 'Report', id: revisionId },
          // 'ReportShares'
        ],
      }),

      bulkIssue: builder.mutation<BulkIssueResponse, { 
        revisionId: number; 
        recipients: { user_id: number; allow_share: boolean; allow_export: boolean }[];
        action?: 'keep' | 'withdraw' | 'supersede';
        dry_run?: boolean;
      }>({
        query: ({ revisionId, recipients, action, dry_run }) => ({
          url: `/report-revisions/${revisionId}/bulk-issue`,
          method: 'POST',
          body: { recipients, action, dry_run },
        }),
        invalidatesTags: (result, error, { revisionId, dry_run }) => {
          // Skip cache invalidation if this is a dry run
          if (dry_run) return []

          return [
            { type: 'ReportRevision', id: revisionId },
            { type: 'ReportRevision', id: 'LIST' },
          ]
        },
      }),

      supersede: builder.mutation<void, { revisionId: string; replacementId: string }>({
        query: ({ revisionId, replacementId }) => ({
          url: `report/revisions/${revisionId}/supersede`,
          method: 'POST',
          body: {
            replacement_id: replacementId
          }
        }),
        // Optionally invalidate the revisions list cache after superseding
        invalidatesTags: (result, error, { revisionId }) => [
          { type: 'ReportRevision', id: revisionId.toString() },
          { type: 'ReportRevision', id: 'LIST' },
        ],
      }),

      // Add more mutations here

    }),
  });

export const {
  useGetReportIssuesQuery,
  useAddReportIssueMutation,
  useUpdateReportIssueMutation,
  useDeleteReportIssueMutation,
  useCreateTokenForReportMutation,
  useBulkUpdateTestItemsMutation,
  useWithdrawRevisionMutation,
  // Shareable link endpoints
  useCreateShareableLinkMutation,
  useToggleLinkStatusMutation,
  useSaveReportLinkMutation,
  useRemoveSavedLinkMutation,
  //new api for share report
  useShareReportLinkMutation,
  useGetSavedLinksQuery,
  //new api for share report
  useShareReportMutation,
  useBulkIssueMutation,
  useSupersedeMutation,

} = reportAPISlice;
