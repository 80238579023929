import { authActions } from '../auth';
// eslint-disable-next-line
import { rootStore } from '@app.raytd.com/store';
import apiURL from './url';
import APIError from './apiError';

export const api = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
  patch: request('PATCH'),
};

type method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
type Headers = Record<string, string>;

const defaultHeaders: Headers = {
  'Content-Type': 'application/json',
  accept: 'application/json',
};

function request(method: method) {
  return (url: string, body?: any) => {
    url = apiURL(url);

    const isFormSubmission = body instanceof FormData;

    if (isFormSubmission) {
      delete defaultHeaders['Content-Type'];
    }

    const requestOptions: RequestInit = {
      method,
      headers: {
        ...defaultHeaders,
        ...authHeader(url),
      },
      credentials: 'include',
    };

    if (body) {

      if (isFormSubmission) {
        requestOptions.body = body;
      } else {
        requestOptions.body = JSON.stringify(body);
      }

    }

    console.info('request headers', requestOptions.headers);

    return fetch(url, requestOptions).then(handleResponse);
  };
}

function authHeader(url: string): Headers {
  // return auth header with jwt if user is logged in and request is to the api url
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL as string);

  const csrf = csrfToken();

  console.info('apiURL', { url, isApiUrl }  );
  console.info('authHeader', { token, isLoggedIn, isApiUrl, csrf });

  if (!isApiUrl) {
    console.error('Not an API URL', url);
  }

  const headers = {} as Headers;

  if (csrf) {
    headers['X-XSRF-TOKEN'] = csrf;
  }

  if (isLoggedIn && isApiUrl) {
    return {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      ...headers,
    };
  }
}

function authToken() {
  const state = rootStore.getState();
  const token = state.auth.user?.token || state.sharedReports.accessToken;
  // const auth = rootStore.getState().auth;
  // return auth.user?.token;
  return token;
}

function csrfToken() {
  const auth = rootStore.getState().auth;
  return auth.xsrfToken;
}

async function handleResponse(response: Response) {
  const isJson = response.headers
    ?.get('content-type')
    ?.includes('application/json');
  const data = isJson ? await response.json() : null;

  console.info('response', {isJson, response, data});

  const reply = {
    ok: response.ok,
    body: data,
    status: response.status,
    statusText: response.statusText,
  };

  if (response.status === 419) {
    return Promise.reject(new Error('CSRF Token expired'));
  }

  // check for error response
  if (!response.ok) {
    //should only reject if 401, 403 just doesn't have permission.
    if ([401].includes(response.status) && authToken()) {
      console.debug('Logging out due to 401', response);
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      const logout = () => rootStore.dispatch(authActions.logout());
    //  logout();
    }

    // get error message from body or default to response status
    const error = data?.error || data?.message || response.status;
    console.debug('Rejecting with error', error, reply);
    return Promise.reject(new APIError(error, reply));
  }

  return reply;
}
