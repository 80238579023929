interface ApiError {
  status: number;
  data: {
    errorCode: string;
    message: string;
  };
}

function isAPIError(error: unknown): error is ApiError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as ApiError).status === 'number' &&
    'data' in error &&
    typeof (error as ApiError).data === 'object' &&
    error.data !== null &&
    'errorCode' in (error as ApiError).data &&
    'message' in (error as ApiError).data
  );
}

function hasStatusCode(error: unknown): error is { status: number } {
  return (
    typeof error === 'object' &&
    error !== null &&
    'status' in error &&
    typeof (error as { status: number }).status === 'number'
  );
}

export { isAPIError, hasStatusCode };
