import { UsersEntity } from '../users';
import { rAPI } from './rAPI';
import { authActions } from '../auth/auth.slice';

// This returns the same API slice reference but with updated types
export const authAPISlice = rAPI
  .enhanceEndpoints({
    addTagTypes: ['Auth'],
  })
  .injectEndpoints({
    overrideExisting: false,
    // Add new tag types

    endpoints: (builder) => ({
      getUsersList: builder.query<UsersEntity[], void>({
        query: () => '/users/list',
        // transformResponse: (response: { data: UsersEntity[] }) => response.data,
      }),
      startImpersonation: builder.mutation<
        {
          message: string;
          user: UsersEntity;
          access_token: string;
          original_token: string;
        },
        number
      >({
        query: (userId) => ({
          url: `/impersonate/start/${userId}`,
          method: 'POST',
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            const { data } = await queryFulfilled;
            localStorage.setItem('original_token', data.original_token);
            dispatch(authActions.setToken(data.access_token));
            dispatch(authActions.setImpersonating(true));
          } catch (error) {
            console.error('Failed to store impersonation tokens:', error);
          }
        },
        invalidatesTags: ['Auth'],
      }),
      stopImpersonation: builder.mutation<
        { message: string; user: UsersEntity },
        void
      >({
        query: () => ({
          url: '/impersonate/stop',
          method: 'POST',
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          try {
            const originalToken = localStorage.getItem('original_token');
            if (originalToken) {
              dispatch(authActions.setToken(originalToken));
              dispatch(authActions.setImpersonating(false));
              localStorage.removeItem('original_token');
            }
            await queryFulfilled;
          } catch (error) {
            console.error('Failed to restore original token:', error);
          }
        },
        invalidatesTags: ['Auth'],
      }),
    }),
  });

export const {
  useGetUsersListQuery,
  useStartImpersonationMutation,
  useStopImpersonationMutation,
} = authAPISlice;
